<template>
  <div
    class="popup_wrap unno_pop"
    style="width:800px;"
  >
    <!-- popup_wrap 팝업사이즈 width:800px; height:540px; -->
    <button
      type="button"
      class="layer_close"
      @click.prevent="$emit('close')"
    >
      {{ $t('msg.CMBK100_M1.038') }}
    </button> <!-- Close -->
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">
        {{ $t('common.UNNO_SEARCH.001') }}
      </h1> <!-- UNNO 검색결과 -->
      <form id="unnoPopForm" action="return false;" @submit.prevent>
        <!-- search_box -->
        <div class="content_box">
          <div class="clearfix">
            <span class="tit_label">{{ $t('msg.SCHD010P020.001') }}</span> <!-- UNNO -->
            <input
              ref="inputUnno"
              id="unno"
              v-model="value"
              type="text"
              style="width:200px;"
              @keypress="unnoEnter"
            >&nbsp;
            <a
              class="button blue sh"
              @click="searchUnno()"
            >{{ $t('msg.CMBA100.00120') }}</a> <!-- 검색 -->
          </div>
        </div>
        <!-- content_box -->
      </form>
      <div class="content_box mt10">
        <div class="flex_box">
          <span class="tot_num">{{ $t('msg.SCHD010P020.002') }} : <span class="num">{{ items.length }}</span></span> <!-- Total : n -->
        </div>
        <table class="tbl_col">
          <colgroup>
            <col width="50px">
            <col width="50px">
            <col width="*">
            <col width="50px">
            <col width="100px">
            <col width="70px">
            <col width="70px">
            <col width="70px">
            <col width="70px">
            <col>
          </colgroup>
          <thead>
            <tr>
              <th>Class</th>
              <th>PG</th>
              <th>{{ $t('msg.CMBA630.002') }}</th> <!-- Proper Shopping Name -->
              <th>SEQ</th>
              <th>Tech. Name</th>
              <th>M/P</th>
              <th>EmS F</th>
              <th>EmS S</th>
              <th>{{ $t('msg.CMBA100.00330') }}</th> <!-- 선택 -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item,index) in items"
              :key="index"
            >
              <td v-html="item.class" />
              <td>{{ item.pakgGrpNm }}</td>
              <td>{{ item.dsc }}</td>
              <td>{{ item.imdgSeq }}</td>
              <td>
                <input
                  v-if="item.mplt === 'Y' || item.mpltCd === 'Y' || item.tchlNmMdtyYn === 'Y'"
                  v-model="item.tchlNm"
                  type="text"
                  name="tchlNm"
                >
              </td> <!-- Tech.Name -->
              <td>
                <select
                  :ref="'mplt_' + index"
                  v-model="item.mpltCd"
                  name="mplt"
                  :disabled="scheduleFlag == 'Y'"
                >
                  <option value="">
                    {{ $t('msg.CSBL200.075') }}
                  </option>
                  <option
                    value="Y"
                    :selected="item.mpltCd === 'Y'"
                  >
                    Y
                  </option>
                  <option
                    value="N"
                    :selected="item.mpltCd === 'N'"
                  >
                    N
                  </option>
                </select>
              </td>
              <td>
                <select
                  v-model="item.emsFeCd"
                  name="emsFeCd"
                  :disabled="item.tchlNmMdtyYn != 'Y'"
                >
                  <option
                    v-for="vo in emsFeCdList"
                    :key="vo"
                    :value="vo"
                  >
                    {{ vo }}
                  </option>
                </select>
              </td>
              <td>
                <select
                  v-model="item.emsSeCd"
                  name="emsSeCd"
                  :disabled="item.tchlNmMdtyYn != 'Y'"
                >
                  <option
                    v-for="vo in emsSeCdList"
                    :key="vo"
                    :value="vo"
                  >
                    {{ vo }}
                  </option>
                </select>
              </td>
              <td>
                <a
                  class="button blue sm"
                  @click.prevent="fnSetUnno(item)"
                >{{ $t('btn.CMBTK047') }}</a>
              </td> <!-- 선택 -->
            </tr>
          </tbody>
        </table>
      </div>
      <!-- content_box // -->
    </div>
  </div>
</template>

<script>

//메모
//unno : 운송 위험 및 유해성이 있는 화학 물질에 부여된 번호
//class : 위험물의 급수(class1 ~ class9)
//imgd  : 국제해상위험물규정
//PG : Package Grade (포장등급) / I : 고위험성, II : 중위험성, III : 저위험성
//Proper Shipping Name : 정식 운송명

import unno from '@/api/rest/trans/unno'

export default {
  name: 'UnnoPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          unno: '',
          selectIdx: '',
          containerId: '', // old : callbackID
          unnoId: '', // old : callbackID2
          uiFlag: '',
          scheduleFlag: 'N',
          cntrTypCd: ''
        }
      }
    }
  },
  data () {
    return {
      value: this.parentInfo.unno,
      fixedValue: '',
      emsFeCdList: [],
      emsSeCdList: [],
      items: [],
      scheduleFlag: this.parentInfo.scheduleFlag
    }
  },
  created () {
    this.initSelect()
    this.pageInit()
  },
  mounted: function () {
    console.log('mounted')
    this.$nextTick(() => {
      this.$refs.inputUnno && this.$refs.inputUnno.focus()
    })
  },
  methods: {
    pageInit () {
      console.log('this.parentInfo.unno ===> ', this.parentInfo.unno)

      this.value = this.parentInfo.unno
      //this.fixedValue = this.parentInfo.unno

      const $vm = this

      //API 호출 메소드

      if (this.$ekmtcCommon.isNotEmpty(this.value)) {
        $vm.searchUnno()
      }
    },
    initSelect () {
      for (let i = 65; i <= 74; i++) { // 대문자 A-J
        this.emsFeCdList.push(String.fromCharCode(i))
      }

      for (let i = 65; i <= 90; i++) { // 대문자 A-Z
        this.emsSeCdList.push(String.fromCharCode(i))
      }
    },
    unnoEnter (e) {
      if (e.keyCode === 13) {
        this.searchUnno()
      }
    },
    //기본 API 검색 메소드
    searchUnno () {
      //입력값 없는 경우 벨리데이션 처리
      if (this.$ekmtcCommon.isNotEmpty(this.value)) {
        const arrCheckUnno = ['2211', '3314', '1013']

        if (this.parentInfo.scheduleFlag !== 'Y' && arrCheckUnno.indexOf(this.value) === -1 && this.parentInfo.cntrTypCd === 'OT') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.391'))
          return
        }
        this.fixedValue = this.value
        this.items = []
        //api call
        unno.getUnno(this.value).then((data) => {
          const list = this.arrangingData(data.data)
          //셋팅할 데이터 to array
          list.forEach((item) => {
            item.tchlNm = ''
            this.items.push(item)
          })
        })

        this.$nextTick(() => {
          const div = document.querySelector('.unno_pop')
          const selector = div.querySelector('#unno')

          this.$ekmtcCommon.hideErrorTooltip(selector)
        })
      } else {
        this.$nextTick(() => {
          const div = document.querySelector('.unno_pop')
          const selector = div.querySelector('#unno')

          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.SCHD010P020.003'))
        })
      }
    },
    //화면에 텍스트 등 가공해서 보여주기 위한 작업 메소드
    arrangingData (list) {
      const arrangedList = list.map((data) => {
        //class arrange
        let classStr = ''
        classStr += data.sbRiskVal1 !== '' && data.sbRiskVal1 !== undefined ? '</br>' + data.sbRiskVal1 : ''
        classStr += data.sbRiskVal2 !== '' && data.sbRiskVal2 !== undefined ? '</br>' + data.sbRiskVal2 : ''
        classStr += data.sbRiskVal3 !== '' && data.sbRiskVal3 !== undefined ? '</br>' + data.sbRiskVal3 : ''
        data.class = data.imoClasCd + classStr

        //PG arrange
        if (data.pakgGrpCd === '01') {
          data.pakgGrpNm = 'III'
        } else if (data.pakgGrpCd === '02') {
          data.pakgGrpNm = 'II'
        } else if (data.pakgGrpCd === '03') {
          data.pakgGrpNm = 'I'
        } else if (data.pakgGrpCd === '04') {
          data.pakgGrpNm = ''
        }

        return data
      })
      return arrangedList
    },
    //Unno 선택시 화면에 input할 위험물 Outer Type 리스트 가져오기
    async fnDgOuterSet (formData) {
      let dgOuterList = []
      await unno.getDgOuterList(formData).then((data) => {
        dgOuterList = data.data
      })

      return dgOuterList
    },
    //UNNO 선택시 화면에 input할 위험물 Additional Information 제목
    async fnDgAddInfoMsg (formData) {
      let addInfoMsgList = []
      await unno.getDgAddInfoMsg(formData).then((data) => {
        addInfoMsgList = data.data
      })

      return addInfoMsgList
    },
    // old : fnClassIDSelect
    async fnSetUnno (item) {
      let techName = item.tchlNm
      //HS가 뭔지 파악 필요
      if (techName !== undefined) {
        item.tchlNm = techName.trim()
      }

      const arrCheckUnno = ['2211', '3314']
      if (this.parentInfo.scheduleFlag !== 'Y' && arrCheckUnno.indexOf(item.imdunNo) > -1 && this.parentInfo.cntrTypCd !== 'OT') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.390'))
        return
      }

      if ((item.tchlNmMdtyYn === 'Y' || item.mpltCd === 'Y') && this.$ekmtcCommon.isEmpty(item.tchlNm)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.SCHD010P020.004')) //Please input compulsory data Technical name contributing to Marine Pollutant.
        return
      }

      if (this.$ekmtcCommon.isEmpty(item.mpltCd)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.SCHD010P020.005')) //Please Select compulsory data Marine Pollutant
        return
      }

      const cntrTypCd = this.parentInfo.cntrTypCd
      //opener화면의 Outer Type을 동적으로 그려줌(외장 타입?)
      //cntrTypeCd는 opener에서 가져옴
      const dgOuterList = await this.fnDgOuterSet({ imdunNo: item.imdunNo, imdgSeq: item.imdgSeq, cntrTypeCd: cntrTypCd })
      //opener화면의 Additional Information을 동적으로 그려줌
      const addInfoMsg = await this.fnDgAddInfoMsg({ imdunNo: item.imdunNo, imdgSeq: item.imdgSeq })

      item.dgOuterList = dgOuterList
      item.addInfoMsg = addInfoMsg
      item.selectIdx = this.parentInfo.selectIdx
      this.$emit('selectFunc', item)
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
</style>
