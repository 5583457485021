import Send from '../../trans.client.js'

export default {
    //Unno 조회
    getUnno (unno) {
        return Send({
            url: '/trans/unno/' + unno,
            method: 'get'
        })
    },

    //Unno 조회
    getDgUnno (unno) {
      return Send({
          url: '/trans/unno/dg/' + unno,
          method: 'get'
      })
    },

    //Unno 선택시 화면에 input할 위험물 Outer Type 리스트 가져오기
    getDgOuterList (formData) {
      return Send({
        url: '/trans/unno/dg-outer-type',
        method: 'get',
        params: {
          imdunNo: formData.imdunNo,
          imdgSeq: formData.imdgSeq,
          cntrTypeCd: formData.cntrTypeCd
        }
      })
    },

    getDgInnerList (formData) {
      return Send({
        url: '/trans/unno/dg-inner-type',
        method: 'get',
        params: formData
      })
    },

    //UNNO 선택시 화면에 input할 위험물 Additional Information 제목
    getDgAddInfoMsg (formData) {
      return Send({
          url: '/trans/unno/add-info-message',
          method: 'get',
          params: {
            imdunNo: formData.imdunNo,
            imdgSeq: formData.imdgSeq
          }
      })
    },

    getDgAddInfoList (seq) {
      return Send({
        url: '/trans/unno/add-info-message-list',
        method: 'get',
        params: {
          seq: seq
        }
      })
    }
}
